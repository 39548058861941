import { PopupButton, Widget } from "@typeform/embed-react";

export const GetInvolved = () => {
  return (
    <div className="pt-28 pb-14 lg:pt-20 lg:pb-20 px-8 w-full flex items-center justify-evenly lg:px-20 lg:justify-center">
      <div className="flex flex-col lg:w-2/3 items-center">
        <div className="flex flex-col lg:justify-center lg:items-start w-full md:w-[50%]">
          <h2 className="text-4xl lg:text-[45px] lg:leading-[50px] py-2 text-dark-purple font-bold">
            How to get involved
          </h2>
          <p className="text-dark-purple text-xl font-bold py-2">
            We&apos;re building a community, united in a shared vision of a
            safer world.{" "}
          </p>
          <p className="text-white text-sm lg:text-[20px] lg:leading-[30px] font-bold py-4 lg:py-3 mb-4 lg:mb-2">
            Together, through shared experiences, we can make this mission a
            reality. Join us for our inaugural product launch.
          </p>
        </div>

        <div className="flex flex-col lg:justify-center lg:items-start md:w-[50%]">
          <div className=" flex flex-col lg:justify-center lg:items-start lg:pt-10">
            <h3 className="text-4xl lg:text-[45px] lg:leading-[50px] py-2 lg:text-left lg:py-3 text-dark-purple font-bold">
              Become eligible for our BETA testing
            </h3>{" "}
            <p className="text-dark-purple text-xl font-bold py-4">
              At ZAHN, we prioritise inclusivity, representation, and equity.
            </p>
            <p className="text-white font-bold text-sm lg:text-[20px] lg:leading-[30px] py-2 lg:py-3">
              We are committed to bringing together a diverse group of
              participants to help shape our understanding of what safety means
              to you!
            </p>
          </div>{" "}
          <p className="text-white font-bold text-sm lg:text-[20px] lg:leading-[30px] py-2 lg:py-3 mb-4">
            Join us in creating a mobile app for everyone, because everyone
            deserves to feel safe. Take our 3-minute quiz to join our cohort of
            BETA testers!
          </p>
        </div>
        <div className="flex lg:hidden justify-center w-full md:w-1/2">
          <PopupButton
            className="text-dark-purple w-full transition duration-500 hover:text-white hover:bg-dark-purple rounded-full p-2 py-3 text-sm md:text-base font-bold hover:border-dark-purple border-2 border-white"
            id="CGipGhpF"
          >
            Join our community
          </PopupButton>
        </div>
      </div>
      <div className="hidden lg:flex w-1/3 h-full justify-start items-center">
        <Widget id="CGipGhpF" style={{ height: "680px" }} className="w-3/4" />
      </div>
    </div>
  );
};
